import sortBy from "lodash/sortBy";
import filter from "lodash/filter";
import head from "lodash/head";
import some from "lodash/some";
import flatten from "lodash/flatten";

//@ts-ignore
const vis = require("vis-network/standalone/esm/vis-network");

type GraphVertexNeighbour = { id: string; distance: number };
type GraphVertex = { id: string; neighbours: Array<GraphVertexNeighbour> };
type Graph = Record<string, GraphVertex>;

const regexp = /\(([0-9]+)\)([\<]?)-\[([0-9]+)\]-([\>]?)\(([0-9]+)\)/;

const hasLt = (lt: string) => lt === "<";
const hasGt = (gt: string) => gt === ">";
const createNode = (id: string) => ({ id, neighbours: [] });
const neighboursOf = (graph: Graph, id: string) => graph[id]?.neighbours;

const graphConnections = [
  "(0)<-[1]-(1)",
  "(0)<-[2]->(5)",
  "(0)<-[3]->(4)",
  "(1)-[1]->(2)",
  "(1)<-[2]->(6)",
  "(1)<-[3]->(5)",
  "(2)<-[1]->(3)",
  "(2)<-[2]->(6)",
  "(2)<-[3]->(7)",
  "(3)<-[1]->(7)",
  "(7)<-[2]->(8)",
  "(4)<-[3]->(5)",
  "(5)<-[1]->(6)",
  "(6)<-[2]->(7)",
  "(5)<-[3]->(9)",
  "(6)<-[1]->(9)",
  "(7)<-[2]->(9)",
  "(1)<-[3]->(4)",
  "(2)<-[1]->(5)",
  "(3)<-[2]->(6)",
  "(3)<-[3]->(8)",
  "(9)<-[1]->(13)",
  "(9)<-[2]->(14)",
  "(9)-[3]->(15)",
  "(13)<-[1]->(14)",
  "(14)<-[2]->(15)",
  "(15)<-[3]->(16)",
  "(16)<-[1]->(17)",
  "(16)<-[2]->(10)",
  "(10)<-[3]->(17)",
  "(10)<-[1]->(11)",
  "(10)<-[2]->(18)",
  "(17)<-[3]->(18)",
  "(17)<-[1]->(11)",
  "(11)<-[2]->(12)",
  "(11)<-[3]->(19)",
  "(11)<-[1]->(18)",
  "(18)<-[3]->(19)",
  "(18)<-[2]->(12)",
  "(12)<-[1]->(19)",
];

const graph = graphConnections.reduce((acc: Graph, curr) => {
  const [match, nodeA, lt, distanceAsString, gt, nodeB] = curr.match(regexp);

  if (match !== curr) {
    throw new Error(`Invalid graph connection definition: '${curr}'`);
  }

  const distance = parseInt(distanceAsString, 10);

  if (hasLt(lt)) {
    // relazione da nodeB verso nodeA
    if (!(nodeB in acc)) {
      acc[nodeB] = createNode(nodeB);
    }
    acc[nodeB].neighbours = [...acc[nodeB].neighbours, { id: nodeA, distance }];
    // digraph.push(`${nodeB} -> ${nodeA}[penwidth=${distance}]`)
  }

  if (hasGt(gt)) {
    // relazione da nodeA verso nodeB
    if (!(nodeA in acc)) {
      acc[nodeA] = createNode(nodeA);
    }
    acc[nodeA].neighbours = [...acc[nodeA].neighbours, { id: nodeB, distance }];
    // digraph.push(`${nodeA} -> ${nodeB}[penwidth=${distance}]`)
  }

  return acc;
}, {});

// console.log(JSON.stringify(graph, undefined, 2))

const vertices = Object.keys(graph);

// scelgo from e to, due vertici casuali all'interno del grafo
const from: keyof typeof graph =
  vertices[Math.floor(Math.random() * vertices.length)];
const to: keyof typeof graph =
  vertices[Math.floor(Math.random() * vertices.length)];

document.title = `From ${from} to ${to} - ${document.title}`;

const visNetwork = {
  nodes: new vis.DataSet(
    vertices.map((vertex) => ({ id: vertex, label: vertex }))
  ),
  edges: new vis.DataSet(
    flatten(
      Object.values(graph).map((vertex: GraphVertex) =>
        vertex.neighbours.map((neighbour: GraphVertexNeighbour) => ({
          from: vertex.id,
          to: neighbour.id,
          arrows: { to: { enabled: true } },
          id: `edge_${vertex.id}_${neighbour.id}`,
          label: `${neighbour.distance}`,
        }))
      )
    )
  ),
};

// create a network
const container = document.getElementById("mynetwork");
const options = {};
const network = new vis.Network(container, visNetwork, options);

if (from === to) {
  throw new Error(
    "La malasorte ha voluto che il nodo di partenza e di destinazione coincidessero"
  );
}

console.log({ graph, vertices, from, to });

// Dijkstra algo

type Potential = {
  id: string;
  value: number;
  final: boolean;
  previousId?: string;
};
const potentials: Record<string, Potential> = {};

// inizializzo i potenziali
for (const vertex of vertices) {
  if (vertex !== from) {
    // assegno potenziale infinito a tutti i nodi del grafo che non sono "from"
    potentials[vertex] = { id: vertex, value: Infinity, final: false };
  } else if (vertex === from) {
    // assegno potenziale 0 al nodo di partenza (distanza 0 da se stesso)
    potentials[vertex] = { id: vertex, value: 0, final: false };
  }
}

//console.log({ potentials })
let iterationCounter = 0;
while (some(potentials, { final: false })) {
  // scelgo il nodo con potenziale minore non ancora finalizzato
  const lowestPotentialVertex: Potential | undefined = head(
    sortBy(filter(potentials, { final: false }), "value")
  );

  if (lowestPotentialVertex) {
    // lo rendo final
    potentials[lowestPotentialVertex.id] = {
      ...lowestPotentialVertex,
      final: true,
    };

    // computo i potenziali dei vertici collegati
    const neighbours = neighboursOf(graph, lowestPotentialVertex.id);
    for (const neighbour of neighbours) {
      const oldNeighbourPotentialValue = potentials[neighbour.id].value;
      const newNeighbourPotentialValue =
        neighbour.distance + lowestPotentialVertex.value;
      const keepOldValue =
        oldNeighbourPotentialValue < newNeighbourPotentialValue;

      potentials[neighbour.id] = {
        ...potentials[neighbour.id],
        value: keepOldValue
          ? oldNeighbourPotentialValue
          : newNeighbourPotentialValue,
        previousId: keepOldValue
          ? potentials[neighbour.id].previousId
          : lowestPotentialVertex.id,
      };
    }
  }
  iterationCounter++;
  //console.log({ iterationCounter, lowestPotentialVertex, potentials })
}

let curr = to;
const solution = [];
while (curr !== from) {
  solution.push(curr);
  curr = potentials[curr].previousId;
}
solution.push(from);
solution.reverse();

setTimeout(() => {
  network.setSelection(
    {
      nodes: solution,
      edges: solution.reduce((acc, curr, index, arr) => {
        if (index < arr.length - 1) {
          acc.push(`edge_${curr}_${arr[index + 1]}`);
        }
        return acc;
      }, []),
    },
    {
      unselectAll: true,
      highlightEdges: false,
    }
  );
}, 0);

console.log({
  // digraph: digraph.join(';'),
  solution: {
    nodes: solution.join(" -> "),
    distance: potentials[to].value,
  },
  iterationCounter,
});
